// Main Dashboard Component.
import React, { useState, useEffect } from 'react';
import MachineTileListComponent from './machineTileListComponent';
import CommonFns from '../common/commonFns';
import { DashboardContext } from '../app';
import IDashboard from '../interfaces/dashboard';
import SignOutButtonComponent from './signOutButtonComponent';
import axios from 'axios';

// Global vars sat outside of react render loop becuase of state reseting issues
// due to MachineTileListComponent also using same state
const refreshEveryXSeconds: number = 120;
const calculateTimeRemainingEveryXSeconds: number = 1;
let selectedDate: Date = new Date();
let selectedDateFormatted: string = CommonFns.getFormattedDate(selectedDate);
let selectedDepot: string = "Wakefield";
let showCalendar: boolean = false;
let milliSecondsRemainingUntilNextRefresh: number = refreshEveryXSeconds * 1000;
let deadline: any = new Date(Date.now() + (refreshEveryXSeconds * 1000));
let calendarHtml: Array<string> = CommonFns.getNextXDaysShortDatesHtml(6);
let currentActiveCalendarDateTab: number = 0;
let depotlist: any = [];
let accounts: any = null;
let username: string = '';

const DashboardComponent: React.FC<any> = ({ msalAccounts, msalInstance }) => {
    // throw ('Ka-boom!'); //Throw an error to test BoundaryError Component

    // Define initial Timeout in state (seconds), FN to update (setSeconds) and initial value
    const [seconds, setSeconds] = useState(refreshEveryXSeconds);
    //accounts = msalAccounts;
    //username = accounts[0].username

    // Value to indicate if he dashboard data is updating
    const [isUpdating, setIsUpdating] = useState(false);

    // Is the app in Error State
    const [isInError, setIsInError] = useState({ hasError: false, message: null });

    // Define Dashboard Data State
    const [dashboard, setDashboard] = useState({
        lastUpdated: new Date(),
        machineTiles: [],
        date: null,
        time: null,
        pdiTotalOverall: 0,
        pdiTotalToday: 0
    });

    // Fn to calc the next time to refresh the dash (the Deadline)
    const calculateNewDeadline = () => {
        return new Date(Date.now() + (refreshEveryXSeconds * 1000));
    }

    // Fn to reset the deadline
    const resetDeadline = () => {
        deadline = calculateNewDeadline();
        milliSecondsRemainingUntilNextRefresh = refreshEveryXSeconds * 1000;
    };

    // Fn triggered when user selects a new day in the drop down calendar control
    const depotSelected = (event: any) => {
        if (event.target.value) {
            selectedDepot = event.target.value;
            // getLatestDashboardData is called via an effect further down
        }
    };

    // Fn triggered when user selects a new day in the drop down calendar control
    const calendarDateSelected = (event: any) => {
        if (event.target.value) {
            const today: any = new Date();
            const targetDate: any = Date.parse(event.target.value);
            const differenceInDays = Math.ceil(Math.abs(targetDate - today) / (1000 * 60 * 60 * 24));

            changeDashboardDate(differenceInDays);
        }
    };

    // Fn to change the date the dashboard focuses on for its data
    const changeDashboardDate = (daysAheadFromToday: number) => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + daysAheadFromToday);
        currentActiveCalendarDateTab = daysAheadFromToday;
        selectedDate = newDate;
        selectedDateFormatted = CommonFns.getFormattedDate(selectedDate);

        getLatestDashboardData();
    };

    // Refresh the dashboard state object
    const refreshDashboardState = () => {
        updateDashboardTime();
        calculateTimeRemainingUntilDeadline();
    };

    // Update the Time displayed on the dashboard (held in State)
    const updateDashboardTime = () => {
        setDashboard(previousState => {
            return { ...previousState, time: CommonFns.getFormattedTime(new Date()) }
        });
    };

    // Clear any app error from State to hide the Error pop-up
    const clearError = () => {
        setIsInError(previousState => {
            return { ...previousState, message: null, hasError: false }
        })
    };

    // Retry updating the dashboard data afetr an error
    const retry = () => {
        clearError();
        getLatestDashboardData();
    };

    // Fn to calculate the time remaining until the deadline
    const calculateTimeRemainingUntilDeadline = () => {
        milliSecondsRemainingUntilNextRefresh = deadline - Date.now();

        // Check if the deadline has been hit
        if (milliSecondsRemainingUntilNextRefresh <= 0) {
            // Its been reached so update the data and then reset the deadline into the future
            resetDeadline();
            getLatestDashboardData();
        }

        // Update state with the number of seconds until the deadline 
        setSeconds(Math.floor((milliSecondsRemainingUntilNextRefresh / 1000) % refreshEveryXSeconds));
    }

    // Replace dashboard data in state with new data
    const updateDashboardInState = (newTileData: any) => {
        setDashboard(previousState => {
            return { ...previousState, machineTiles: newTileData.machineTiles, pdiTotalOverall: newTileData.pdiTotalOverall, pdiTotalToday: newTileData.pdiTotalToday, lastUpdated: newTileData.lastUpdated }
        });
    }

    // FN to call home for updated dashboard data
    const getLatestDashboardData = () => {
        setIsUpdating(true);

        // API_URL is a Global Var substituted by webpack at build time.
        // Address is configured in the relevant webpack environment file and 
        // pushed into Global scope on build. See globals.tsx
        axios.get(`${API_URL}/get/${selectedDepot}?date=${CommonFns.getFormattedDateYYYYMMDD(selectedDate)}&t=${new Date().getTime()}`).then(response => {
            if (response.data) {
                const dashboardData: IDashboard =
                {
                    lastUpdated: new Date(),
                    machineTiles: response.data.machineModels,
                    pdiTotalOverall: response.data.pdiTotalOverall,
                    pdiTotalToday: response.data.pdiTotalToday,
                }

                updateDashboardInState(dashboardData);
            }
        }).catch(error => {
            console.log('Axios Error:', error);

            setIsInError(previousState => {
                return { ...previousState, message: error.message, hasError: true }
            });
        }).then(() => {
            setIsUpdating(false);
        });
    };

    const getDepotList = () => {
        setIsUpdating(true);

        axios.get(`${API_URL}/get-depots`).then(response => {
            if (response.data) {
                response.data.forEach((x:any ) => {
                    const existingItem:any = depotlist.find((item: any) => item.value === x.value);

                    if (!existingItem) {
                        depotlist.push(x);
                    }
                });
            }
        }).catch(error => {
            console.log('Axios Error:', error);

            setIsInError(previousState => {
                return { ...previousState, message: error.message, hasError: true }
            });
        }).then(() => {
            setIsUpdating(false);
        });
    };

    async function _acquireAccessToken(msalApp: any) {
        const request = {
            account: accounts[0],            
            scopes: [API_SCOPES]  
        };

        const authResult = await msalApp.acquireTokenSilent(request);

        return authResult.accessToken;
    }

    // A 'useEffect' runs every render cycle, but if we pass in [] then it only runs once :)
    // Use this to kick off proceedings
    useEffect(() => {
        // Set Msal
        accounts = msalAccounts.accounts;

        if (accounts && accounts[0].username) {  
            username = accounts[0].username.split('@')[0];
            const userFirstLastNames: any = username.split('.');
            username = `${userFirstLastNames[1] ? userFirstLastNames[0].substring(0, 1) + '.' + userFirstLastNames[1] : userFirstLastNames[0]}`;

            axios.interceptors.request.use(async (config: any) => {
                const accessToken = await _acquireAccessToken(msalInstance);

                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }

                return config;
            });
        }

        //console.log('msal', accounts);
        // Set initial date time
        setDashboard(previousState => {
            return { ...previousState, date: CommonFns.getFormattedDate(new Date()), time: CommonFns.getFormattedTime(new Date()) };
        });

        // Grab initial dashboard data
        getDepotList();

        // Create an interval to run every X seconds to calc remaining time to deadline
        const interval = setInterval(
            refreshDashboardState,
            calculateTimeRemainingEveryXSeconds * 1000);

        return () => clearInterval(interval);
    }, []);

    // An Effect to trigger when the Selected Depot Changes
    useEffect(() => {
        // Only show calendar if Selected Depot is not Wakefield
        showCalendar = selectedDepot.toLowerCase() !== 'wakefield';

        if (showCalendar === false) {
            // Reset calender date back to today (Fn also resets data)
            changeDashboardDate(0);
        }
        else {
            getLatestDashboardData(); 
        }
    }, [selectedDepot])

    return (
        <>
            <DashboardContext.Provider value={dashboard}>
                <div className="nav">
                    <div className="nav__logo">
                        <img src="images/logo.png" alt="Horizon Platforms logo" />
                    </div>
                    <div className="nav__options">
                        <button className="option active"><span className="icon icon--service-red" title="View the Service dashboard"></span><span className="text">Service</span></button>
                        <button className="option"><span className="icon icon--transport-red" title="View the Transport dashboard"></span><span className="text">Transport</span></button>
                        <SignOutButtonComponent instance={ msalInstance } />
                    </div>
                </div>
                <div className="dash">
                    <div className="blades">
                        <div className="blade blade--1">
                            <div className="blade__sub">
                                <div className="tile tile--transparent">
                                    <div className="calendar">
                                        <div className="hanger"></div>
                                        <div className="hanger hanger--r"></div>
                                        <div className="calendar__date-time">
                                            <span className="date">{dashboard.date}</span>
                                            <span className="time">{dashboard.time}</span>
                                        </div>
                                        {showCalendar === true &&
                                            <>
                                                <div className="hr"></div>
                                                <div className="look-ahead">
                                                    <input className="input--date" type="date" onChange={calendarDateSelected} />
                                                </div>
                                                <div className="calendar__days">
                                                    {
                                                        calendarHtml.map((dayHtml: string, index: number) => (
                                                            <div onClick={() => { changeDashboardDate(index); }} key={index} className={index == currentActiveCalendarDateTab ? 'days__day active' : 'days__day'} dangerouslySetInnerHTML={{ __html: dayHtml }}></div>
                                                        ))
                                                    }
                                                </div>                                           
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="selected-depot">
                                    <label className="label">Depot</label>
                                    <select className="input input--select" onChange={depotSelected}>
                                        {
                                            depotlist.map((depot:any, index: number) => (
                                                <option value={depot.value} key={index} selected={depot.value == selectedDepot}>{depot.value}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="tile">
                                    <div className="tile__head">
                                        <span>PDI Totals</span>
                                    </div>
                                    <div className="tile__data">
                                        <div className="data__option theme--pdi">
                                            <span className="title">In PDI</span>
                                            <span className="data">{dashboard.pdiTotalOverall}</span>
                                        </div>
                                        <div className="data__option">
                                            <span className="title">PDI Reqd</span>
                                            <span className="data alert">{dashboard.pdiTotalToday}</span>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div className="blade blade--2">
                            {
                                isUpdating === true &&
                                <div className="splash splash--updating active">
                                    <div className="loader"></div>
                                </div>
                            }
                            {
                                isInError.hasError === true &&
                                <div className="splash splash--error active">
                                    <div className="error">
                                        <div className="icon icon--error-white"></div>
                                        <h1 className="error__title">Sorry, we have hit a problem!</h1>
                                        <p className="error__text"><span className="dull">err:</span>&nbsp;{isInError.message}</p>
                                        <div className="error__btns">
                                            <button className="btn btn--error" onClick={clearError}>Ok</button>
                                            <button className="btn btn--link" onClick={retry}>retry</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="blade__sub">
                                <div className="head">
                                    <div className="title">
                                        <span>
                                            <span className="dull">Viewing &gt;</span>
                                            <span>&nbsp;{selectedDateFormatted}&nbsp;</span>
                                        </span>
                                        <span className="dull">at Depot &nbsp;</span>
                                        <span>{selectedDepot}&nbsp;</span>
                                        <span className="dull">&nbsp;[{username}]</span>
                                    </div>
                                    <div className="controls">
                                        <div onClick={() => { getLatestDashboardData(); }} className="refresh" title="Time to next refresh, or click to refresh now">
                                            <span className="icon icon--refresh--charcoal"></span>
                                            <div className="refresh__bar" style={{ width: ((refreshEveryXSeconds - seconds) / refreshEveryXSeconds) * 100 + '%' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="data">
                                    <div id="machine-tiles" className="tiles">
                                        <div className="no-data active"></div>
                                        <MachineTileListComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardContext.Provider>
        </>
    );
}

export default DashboardComponent;