// Represents the list of machine tiles in the main dashboard blade
import { useContext } from "react";
import MachineTileComponent from './machineTileComponent';
import IDashboard from "../interfaces/dashboard";
import { DashboardContext } from '../app';

const MachineTileListComponent: React.FC = () => {
    // Get the dashboard data from state
    const dashboard: IDashboard = useContext(DashboardContext);

    // Now build an array of Machine Tile Components that becomes our tile list
    let machineTileComponents = [];

    if (dashboard.machineTiles) {
        for (let i: number = 0; i < dashboard.machineTiles.length; i++) {
            machineTileComponents.push(<MachineTileComponent pos={i} key={i} />)
        }
    }

    return (
        <>
            {machineTileComponents}
        </>
    );
}

export default MachineTileListComponent;