class CommonFns {
    static getFormattedDate(date: Date) {
        var objToday;

        if (date) {
            objToday = date;
        }
        else {
            objToday = new Date();
        }

        var dayOfMonth: any = today + (objToday.getDate() < 10) ? '0' + objToday.getDate() : objToday.getDate(),
            domEnd: string = dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31 ? 'st' : dayOfMonth == 2 || dayOfMonth == 22 ? 'nd' : dayOfMonth == 3 || dayOfMonth == 23 ? 'rd' : 'th',
            months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
            weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            curMonth = months[objToday.getMonth()],
            curYear = objToday.getFullYear(),
            day = weekday[date.getDay()];

        var today = `${day} ${dayOfMonth}${domEnd} ${curMonth} ${curYear}`;

        return today;
    }

    static getFormattedTime(date: Date) {
        var objToday;

        if (date) {
            objToday = date;
        }
        else {
            objToday = new Date();
        }

        var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours()),
            curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes(),
            curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

        var today = `${curHour}:${curMinute} ${curMeridiem}`;

        return today;
    }

    static getNextXDaysShortDatesHtml(numberOfDays: number): string[] {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let nextXDaysShortDates = [];

        for (let i: number = 0; i < numberOfDays; i++) {
            let date = new Date();
            date.setDate(date.getDate() + i);
            const day:string = weekday[date.getDay()];
            const dayOfMonth: any = date.getDate();
            const domEnd: string = dayOfMonth == 1 || dayOfMonth == 21 || dayOfMonth == 31 ? 'st' : dayOfMonth == 2 || dayOfMonth == 22 ? 'nd' : dayOfMonth == 3 || dayOfMonth == 23 ? 'rd' : 'th';

            nextXDaysShortDates.push(`<div data-date="${date}"><span class="name">${day}</span><span class="date">${dayOfMonth}${domEnd}</span></div>`);
        }

        return nextXDaysShortDates;
    }

    static getFormattedDateYYYYMMDD(date: Date) {
        const day: string = String(date.getDate()).padStart(2, '0');
        const month: string = String(date.getMonth() + 1).padStart(2, '0');
        const year: string = String(date.getFullYear());

        return `${year}/${month}/${day}`;
    }
}

export default CommonFns;