// Represents a Machine Tile on the Dashboard
import React from 'react';
import { useContext } from "react";
import IDashboard from '../interfaces/dashboard';
import IMachineTile from '../interfaces/machineTile';
import { DashboardContext } from '../app';


const MachineTileComponent: React.FC<any> = ({ pos }) => {
    // Grab the dashboard data from State
    const dashboard: IDashboard = useContext<IDashboard>(DashboardContext);
    // Get the machine data for this tile based on it's index
    const tileData: IMachineTile = dashboard.machineTiles[pos];

    return (
        <div className={"tile " + (tileData.overallState ? `state--${tileData.overallState}` : "")} >
            {
                (tileData.overallStateReason != null && tileData.overallStateReason != '') &&
                <div className="state-info">
                    <span className="arrow"></span>
                    <p className="state-info__desc">{tileData.overallStateReason}</p>
                </div>
            }
            <div className="machine active">
                <div className="machine__head">
                    <span title="Machine Name" className="name">{tileData.name}</span>
                    <div title="Number of Machines Requiring Pre-Delivery Inspection Today">
                        <span><span className="text">PDI Reqd</span><span className={"data " + (tileData.predeliveryInspectionTotal > 0 ? "active" : "")}>{tileData.predeliveryInspectionTotal}</span></span>
                    </div>
                </div>
                <div className="machine__status">
                    <div className="status__nav">
                        <div className="nav__sub">
                            <span>No Extra Data</span>
                        </div>
                    </div>
                    <div className={"status__option " + (tileData.dueBackState ? `state--${tileData.dueBackState}` : "")} title="Due Back on Trucks">
                        <span className="title"><span className="mb tb dt dt-l">Due</span><span className="dt-xl">Due Back</span></span>
                        <span className="data">{tileData.dueBack}</span>
                    </div>
                    <div className={"status__option " + (tileData.inRepairState ? `state--${tileData.inRepairState}` : "")} title="In for Repair">
                        <span className="title"><span className="mb tb dt dt-l">Rep</span><span className="dt-xl">In Repair</span></span>
                        <span className="data">{tileData.inRepair}</span>
                    </div>
                    <div className={"status__option " + (tileData.pdiState ? `state--${tileData.pdiState}` : "")} title="Require Pre-Delivery Inspection">
                        <span className="title"><span className="mb tb dt dt-l">PDI</span><span className="dt-xl">In PDI</span></span>
                        <span className="data">{tileData.pdi}</span>
                    </div>
                    <div className={"status__option " + (tileData.inDepotState ? `state--${tileData.inDepotState}` : "")} title="Machines in the Depot">
                        <span className="title"><span className="mb tb dt dt-l">Depot</span><span className="dt-xl">In Depot</span></span>
                        <span className="data">{tileData.inDepot}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MachineTileComponent;